
.tag{
    margin-bottom: 10px;
    border-radius: 20px;
 
    padding: 5px 12px 5px 12px;
    font-weight: 600;
    font-size: small;
    color: white;
    margin-right: 8px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;


}

.language-tag{
    background-color: rgb(113, 80, 230);
}

.database-tag{
    background-color: #34bcd7;
}

.cloud-tag{
    background-color: #f683ca;
}

.tools-tag{
    background-color: rgb(235, 124, 91);
}

.datascience-tag{
    background-color: rgb(252, 208, 76);
}

.dataEngineeering-tag{
    background-color: rgb(252, 76, 85);
}
.libraries-tag{
    background-color: rgb(64, 167, 141);
}

.courses-tag{
    background-color: rgb(209 174 237);
}

.half-opacity-language-tag{
    background-color: rgb(113, 80, 230, 0.5);
}

.half-opacity-database-tag{
    background-color: rgb(64, 167, 141,0.5);
}

.half-opacity-cloud-tag{
    background-color: rgb(76, 163, 245,0.5);
}

.half-opacity-tools-tag{
    background-color: rgb(235, 124, 91, 0.5);
}

.half-opacity-libraries-tag{
    background-color: rgb(134, 134, 134, 0.5);
}

@media (max-width: 768px) {


.tag{
    font-weight:600;
    font-size: x-small;
    padding: 2px 8px 2px 8px;
    margin-bottom: 5px;
    margin-right:5px;
}

}
