.parent-div {
    margin-left: 12vw;
    margin-right: 12vw;
    max-width: 100%;
}

.parent-div-top {
    padding-top: 10vh;
}

.nav-link {
    text-decoration: none;
    color: rgb(82, 82, 82);
}

.heading {
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-weight: 500;
    font-family: monospace;
    font-size: x-large;
}

.content {
    font-family: monospace;
    font-size: small;
}


.section {
    font-family: monospace;
    margin-top: 8vh;
}

.section-box {
    background-color: rgb(239, 239, 250);
    box-shadow: 0px 0px 5px rgb(194, 194, 194);
    border-radius: 10px;
    padding: 2vw;
}

.section-heading {
    font-weight: 800;
    font-size: x-large;
    padding-bottom: 1vh;
    color: rgb(82, 82, 82);
}

.section-sub-heading {
    font-weight: 600;
    font-size: large;
    padding-bottom: 1vh;
    color: rgb(106, 105, 105);
}

.section-content {
    font-weight: 600;
    font-size: medium;
    color: rgb(95, 95, 95);
    line-height: 30px;
}


.button {
    width: 100%;
    float: right;
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    background-image: linear-gradient(to right, #3845fa, #8587e4);
    color: white;
    text-decoration: none;
    font-weight: 600;
    font-size: small;
    margin-top: 15px;
    text-align: center;
    box-shadow: 0 0 5px rgb(100, 100, 100);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.page-top-padding{
    padding-top: 10vh;
}

.rounded-edge{
    border-radius: 16px;
}


@media (max-width: 768px) {

    /* .parent-div{
        margin-left: 5%;
        margin-right: 5%;
        max-width: 100%;
    } */
    .button{
        font-size: xx-small;
    }
    .parent-div-top {
        padding-top: 2vw;
    }

    .parent-div {
        margin-left: 8vw;
        margin-right: 8vw;
        max-width: 100%;
    }

    .heading {
        margin-top: 1vh;
        margin-bottom: 0vh;
        font-size: large;
        
    }

    .section {
        margin-top: 8vh;

    }

    .section-box {
        border-radius: 6px;
        padding: 4vw;
    }


    .section-heading {
        font-weight: 800;
        font-size: large;
    }

    .section-sub-heading {
        font-weight: 600;
        font-size: medium;
    }

    .section-content {
        font-weight: 600;
        font-size: small;
     
    }

    .page-top-padding{
        padding-top: 8vh;
    }

}