.work-card {
    width: 22rem;
    padding: 30px ;
    box-shadow: 3px 3px 20px rgb(212, 212, 212);
    border-radius: 12px;
    border: 0 !important;
}

.work-card__image {
    border-radius: 14px;
}

.work-card__body {
    padding-bottom: 0px;
    font-size: medium;
}

.work-card__tag {
    font-weight: 520;
    font-size: large;
    padding-bottom: 18px;
    color: rgb(124, 124, 124);
}

.work-card__title {
    font-weight: 800;
    font-size: large;
    color: rgb(82, 82, 82);
    height: max-content;
}

.work-card__date--beside {
    font-size: large;
    display: inline;
    padding-right: 10px;
    color: rgb(124, 124, 124);
}

.work-card__date--down {
    display: none;
}

.work-card__content{

   max-height: 0;
   overflow: hidden;
   transition-delay: 0ms;
   transition: max-height 0.5s ease-out;
}
.work-card__content-open{

    height: auto;
    max-height: 9999px;
    transition: all 1s ease-in !important;
}

.icon-container {
    font-size: 30px;
}



@media (max-width: 768px) {
    .work-card {
        padding: 6px;
    }

    .work-card__tag {
        font-weight: 600;
        font-size: x-small;
        padding-bottom: 12px;
    }

    .work-card__title {
        font-weight: 800;
        font-size: medium;
    }

    .work-card__date--beside {
        display: none;
    }

    .work-card__date--down {
        font-size: x-small;
        color: rgb(124, 124, 124);
        line-height: 8px;
        margin: 0;
        display: block;
    }

    .icon-container {
        font-size: 14px;
    }
    .work-card__body {
        padding-bottom: 0px;
        font-size: x-small;
    }

    .work-card__content-open{
        padding-top: 12px;
    }
    .work-card__content{
        padding-top: 12px;
    }


}