
.link-box {
    border-radius: 20px;

    padding-left: 25px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;

    font-weight: 550;
    font-size: medium;
    color: rgb(112, 112, 112);


    margin-right: 8px;
    margin-top: 10px;
    margin-bottom: 0;
    box-shadow: 0px 0px 5px rgb(206, 206, 206) inset;
}

.link-box__icon-holder {
    border-radius: 50%;
    margin-right: 10px;
    margin-left: -20px;
    width: 20px;
    height: 20px;
    text-align: left;
}

.icon-size{
    font-size: 24px;
}

@media (max-width: 768px) {


.link-box {
    border-radius: 20px;

    padding-left: 25px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;

    font-weight: 600;
    color: rgb(112, 112, 112);

    font-size: small;

    margin-right: 8px;
    margin-top: 12px;
    margin-bottom: 0;
    box-shadow: 0px 0px 5px rgb(206, 206, 206) inset;
  
}

.link-box__icon-holder {
    border-radius: 50%;
    margin-right: 10px;
    margin-left: -20px;
    width: 20px;
    height: 20px;
    text-align: left;
}
.icon-size{
    font-size: 14px !important;
}
    
}