.project-card {
    padding: 16px;
    box-shadow: 3px 3px 20px rgb(212, 212, 212);
    border-radius: 12px;
    border: 0 !important;
    height: 300px; /* Set a fixed height for consistent card size */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.project-card__image {
    border-radius: 14px;
    box-shadow: 0px 0px 2px rgb(212, 212, 212);
    border: 0.5px solid rgb(230, 230, 230);
    width: 100%; /* Ensure image takes full width of the card */
    height: 300px; /* Set a fixed height for the image */
    object-fit: cover; /* Maintain aspect ratio */
}

.project-card__tag {
    color: rgb(99, 99, 99);
    font-size: 12px;
    margin-bottom: 8px; /* Space between tagline and title */
}

.project-card__title {
    color: rgb(66, 66, 66);
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px; /* Space between title and body */
}

.project-card__body {
    padding-bottom: 0px;
    height: 115px; /* Limit body height to fit within card */
    overflow: hidden; /* Hide overflow text if exceeds height */
}

@media (max-width: 768px) {
    .project-card__title {
        font-size: small; /* Responsive font size adjustment */
    }
}
